const baseUrl = process.env.VUE_APP_BASE_URL || 'https://api.inst.money';
const baseAdminUrl = process.env.VUE_APP_BASE_ADMIN_URL || 'https://admin.inst.money';
const basePayonePayUrl = process.env.VUE_APP_BASE_PAYONE_PAY_URL || 'https://pay.inst.money';
const baseWebsiteRegisterUrl = process.env.VUE_APP_BASE_WEBSITE_REGISTER_URL || 'https://inst.money/#/register';
const basePayonePayHistoryUrl = process.env.VUE_APP_BASE_PAYONE_PAY_HISTORY_URL || 'https://inst.money/buyCrypto/history';
const baseMerchantUrl = process.env.VUE_APP_BASE_MERCHANT_URL || 'https://merchants.inst.money/';

export {
  baseUrl,
  baseAdminUrl,
  basePayonePayUrl,
  baseWebsiteRegisterUrl,
  basePayonePayHistoryUrl,
  baseMerchantUrl,
};

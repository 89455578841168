export const codeEnum = {
  success: 0,
  tokenExpire: 102001,
  noGa: 101011,
  cardImportWarn: 101025,
  statusWrong: 101014,
  userNotLogin: 102007,
};

export const reasonOptions = [
  {
    label: '111003-KYC 失败',
    value: '111003',
    en: 'KYC failure',
  },
  {
    label: '1110031-KYC 失败，country或nationality填写错误',
    value: '1110031',
    en: 'KYC failure, country or nationality error',
  },
  {
    label: '1110032-KYC 失败，证件照片错误',
    value: '1110032',
    en: 'KYC failure, certificate photo error',
  },
  {
    label: '1110033- KYC 失败，手持证件照错误或不够清晰',
    value: '1110033',
    en: 'KYC failure, certificate photo error or photo is not clear',
  },
  {
    label: '1110034-KYC 失败，地址错误',
    value: '1110034',
    en: 'KYC failure, address error',
  },
  {
    label: '1110035-KYC 失败，手机号错误',
    value: '1110035',
    en: 'KYC failure, mobile error',
  },
  {
    label: '1110036-KYC 失败，birthday错误',
    value: '1110036',
    en: 'KYC failure, birthday error',
  },
  {
    label: '1110037-KYC 失败，名字错误',
    value: '1110037',
    en: 'KYC failure, user name error',
  },
  {
    label: '1110038-KYC 失败，邮编错误',
    value: '1110038',
    en: 'KYC failure, zipcode error',
  },
  {
    label: '1110039-KYC 失败，其他错误',
    value: '1110039',
    en: 'KYC failure, other error',
  },
  {
    label: '1110040-KYC 失败，护照过期或即将过期',
    value: '1110040',
    en: 'KYC failure, your passport has expired or will expire in 6 month',
  },
  {
    label: '1110041-KYC 失败，护照不完整或不清晰',
    value: '1110041',
    en: 'KYC failure, the passport is not clear or not complete',
  },
  {
    label: '1110042-KYC 失败，性别填写错误',
    value: '1110042',
    en: 'KYC failure, gender filled error',
  },
  {
    label: '1110043-KYC 失败，手持照不清晰或不完整',
    value: '1110043',
    en: 'KYC failure, selfie photo is not clear or not complete',
  },
  {
    label: '1110044-KYC 失败，手持照中护照文字是反的',
    value: '1110044',
    en: 'KYC failure, the passport text in the selfie photo is reversed',
  },
  {
    label: '1110045-KYC 失败，手持照没露脸',
    value: '1110045',
    en: 'KYC failure, your face is not exposed in the selfie photo',
  },
];

export const bindCardLength = 3;

import axios from 'axios';
import { baseUrl, baseAdminUrl } from './baseUrl';

import request from './request';

export function getRates(params, authorization) {
  return axios({
    method: 'post',
    url: `${baseUrl}/v1/buy/rates`,
    headers: {
      Authorization: authorization,
    },
    data: params,
  });
}

export function buyCurrency(params) {
  return axios({
    method: 'post',
    url: `${baseUrl}/v1/third/buy`,
    headers: {
      Authorization: params.authorization,
    },
    data: {
      cust_order_id: params.cust_order_id,
      from_currency: params.from_currency,
      amount: params.amount,
      to_crypto: params.to_crypto,
      channel_name: params.channel_name,
      address: params.address,
      rate: params.rate,
      return_url: params.return_url,
      pay_with: params.pay_with,
      domain: 'inst.money',
    },
  });
}

export function fastBuyCurrency(params) {
  return request({
    method: 'post',
    url: `${baseUrl}/api/v1/fast/buy`,
    headers: {
      Authorization: params.authorization,
    },
    data: {
      cust_order_id: params.cust_order_id,
      from_currency: params.from_currency,
      amount: params.amount,
      to_crypto: params.to_crypto,
      channel_name: params.channel_name,
      address: params.address,
      rate: params.rate,
      return_url: params.return_url,
      customer: {
        card_number: params.card_number,
      },
    },
  });
}

export function fastBuyCurrencyNew(params, authorization) {
  return request({
    method: 'post',
    url: `${baseUrl}/api/v1/fast_buy`,
    headers: {
      Authorization: authorization,
      'ga-code': params['ga-code'] || '',
    },
    data: {
      ...params,
    },
  });
}

export function rechargePayment(params, authorization) {
  return request({
    method: 'post',
    url: `${baseUrl}/api/v1/card-payment`,
    headers: {
      Authorization: authorization,
    },
    data: params,
  });
}

// admin接口

export function sendEmail(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/common/security/mail`,
    data: params,
  });
}

export function login(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/admin/individual/login`,
    headers: {
      'ga-code': params['ga-code'],
    },
    data: params,
  });
}

export function register(params, emailCode) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/admin/individual/register`,
    headers: {
      'email-code': emailCode,
    },
    data: params,
  });
}

export function resetPasswordFun(params, emailCode) {
  return request({
    method: 'put',
    url: `${baseAdminUrl}/v1/admin/individual/login/reset`,
    headers: {
      'email-code': emailCode,
    },
    data: params,
  });
}

export function getAccountKyc() {
  return request({
    method: 'get',
    url: `${baseAdminUrl}/v1/account/kyc/basic`,
  });
}

export function submitAccountKyc(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/account/kyc/basic`,
    data: params,
  });
}

export function getAccountKycCard(params) {
  return request({
    method: 'get',
    url: `${baseAdminUrl}/v1/account/kyc/card?card_type_id=${params.cardTypeId}`,
  });
}

export function submitAccountKycCard(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/account/kyc/card`,
    data: params,
  });
}

export function getCardList() {
  return request({
    method: 'get',
    url: `${baseAdminUrl}/v1/account/card/type`,
  });
}

export function submitChangePassword(params) {
  return request({
    method: 'put',
    url: `${baseAdminUrl}/v1/customers/login/reset`,
    data: params,
  });
}

export function activeCard(params) {
  const queryParams = {
    token: params.token,
    card_no: params.card_no,
  };
  if (params.activeDoc) {
    queryParams.active_doc = params.activeDoc;
    // queryParams.activeCard = encodeURIComponent(params.activeDoc);
  }
  return request({
    method: 'put',
    headers: {
      'email-code': params.email_token,
    },
    url: `${baseAdminUrl}/v1/account/card/active`,
    data: {
      ...queryParams,
    },
  });
}

export function getCVV(id, gaCode) {
  return request({
    method: 'get',
    headers: {
      'ga-code': gaCode,
    },
    url: `${baseAdminUrl}/v1/account/card/cvv?cardNo=${id}`,
  });
}

export function getCardNo(id, gaCode) {
  return request({
    method: 'get',
    headers: {
      'ga-code': gaCode,
    },
    url: `${baseAdminUrl}/v1/account/card/card-number?cardNo=${id}`,
  });
}


export function getHistory(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/account/card/transaction-record`,
    data: params,
  });
}

export function getUserAccount(id) {
  return request({
    method: 'get',
    url: `${baseAdminUrl}/v1/account/card/balance?cardNo=${id}`,
  });
}

export function getDepositHistory(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/account/card/deposit`,
    data: params,
  });
}

export function getDepositFromWalletHistory(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/wallet/transactions`,
    data: {
      ...params,
      tx_type: '16',
    },
  });
}

export function getBuyCryptoHistory(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/account/card/crypto`,
    data: params,
  });
}

export function submitTxHash(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/account/card/deposit-token`,
    data: params,
  });
}

export function submitCardWithdraw(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/account/card/withdraw`,
    headers: {
      'email-code': params.email_token,
    },
    data: params,
  });
}

export function getDepositAddress(params) {
  return request({
    method: 'get',
    url: `${baseAdminUrl}/v1/account/card/deposit-address?cardNo=${params.cardNo}&coinType=${params.coinType}`,
  });
}

export function getCustCrypto(authorization) {
  return request({
    method: 'get',
    url: `${baseUrl}/api/v1/cust/crypto`,
    headers: {
      Authorization: authorization,
    },
  });
}

export function getCardAsset() {
  return request({
    method: 'get',
    url: `${baseAdminUrl}/v1/account/card/asset`,
  });
}

export function getInviteInfo() {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/invitation/info`,
  });
}

export function getCommissionData() {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/invitation/commission_info`,
  });
}

export function getInviteRewards() {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/invitation/commission`,
  });
}

export function subscription(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/subscription/add`,
    data: params,
  });
}

export function getBindCardList() {
  return request({
    method: 'get',
    url: `${baseAdminUrl}/v1/account/bind-card/card-list`,
  });
}

export function getBindCardInfo(id) {
  return request({
    method: 'get',
    url: `${baseAdminUrl}/v1/account/bind-card?id=${id}`,
  });
}

export function bindCard(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/account/bind-card`,
    data: params,
  });
}

export function getUserWallet() {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/wallet/user`,
  });
}

export function submitCreateOrder(params, authorization) {
  return request({
    method: 'post',
    url: `${baseUrl}/api/v1/deposit`,
    headers: {
      Authorization: authorization,
    },
    data: params,
  });
}

export function createBankTransferNote(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/wallet/deposit`,
    data: params,
  });
}

export function createBankTransferData() {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/wallet/bank`,
  });
}

export function getWithdrawHistory(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/wallet/transactions`,
    data: params,
  });
}

export function gaAuth() {
  return request(`${baseAdminUrl}/v1/common/security/google-authentication`, {
    method: 'get',
  });
}

export function bindGa(params, emailCode) {
  return request(
    `/v1/common/security/google-authentication/v2?mailCodeToken=${params.mailCodeToken}&bindingCode=${params.bindingCode}&bindingKey=${params.bindingKey}`,
    {
      method: 'PUT',
      headers: {
        'email-code': emailCode,
      },
      data: params,
    },
  );
}

export function getGaStatus(mail) {
  return request('/v1/common/security', {
    method: 'get',
    params: {
      mail,
      login_type: '4',
    },
  });
}

export function transferWallet(params, emailCode) {
  return request('/v1/wallet/transfer', {
    method: 'post',
    headers: {
      'ga-code': params['ga-code'],
      'email-code': emailCode,
    },
    data: params,
  });
}

export function transferWalletToCard(params, emailCode) {
  return request('/v1/wallet/transfer_to_card', {
    method: 'post',
    headers: {
      'ga-code': params['ga-code'],
      'email-code': emailCode,
    },
    data: params,
  });
}


export function getWalletRate(params) {
  return axios({
    method: 'post',
    url: `${baseUrl}/v1/buy/wallet_rates`,
    data: params,
  });
}

export function enAbleMailVerify(params) {
  return request('/v1/admin/individual/ga_enable', {
    method: 'post',
    headers: {
      'ga-code': params['ga-code'],
    },
    data: params,
  });
}

export function getUserStatusController() {
  return request({
    method: 'get',
    url: `${baseUrl}/api/v1/risk/user-status`,
  });
}

export function putContactSales(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/sale/add`,
    data: params,
  });
}

export function commissionWithdraw(params, emailCode, gaCode) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/invitation/commission/withdraw`,
    headers: {
      'email-code': emailCode,
      'ga-code': gaCode,
    },
    data: params,
  });
}

export function getCommissionWithdraw() {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/invitation/commission_orders `,
  });
}

export function userFeedback(params) {
  return request({
    method: 'put',
    url: `${baseUrl}/api/v1/risk/user-feedback`,
    data: params,
  });
}

export function submitDepositTxHash(params) {
  return request({
    method: 'post',
    url: `${baseAdminUrl}/v1/account/card/deposit/open-card-fee`,
    data: params,
  });
}
